import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import API from '@/api/API'
import http from '@/api/http'
import { AxiosResponse } from 'axios'
import controllerResponse from '@/api/controllerResponse'
import captureError from '@/api/captureError'
import { ElNotification } from 'element-plus'
import { EPlatform, Project } from '@/api/project'
import { deleteCookie, getCookie, setCookie } from '@/helpers/cookie'
import { EUserRoles } from '@/dict/enum'
import { graphTemplates } from '@/pinia/templates'

export const useStore = defineStore('global', () => {
  const lang = ref('en') // отвечает за язык, пока только en
  const customerId = ref('')
  const managers = ref([])
  const username = ref('')
  const platforms = ref([])
  const roles = ref([])
  const graph = ref([])
  const platform = ref('' as EPlatform)
  const token = ref('')
  const isLoader = ref(false) // глобальный лоадер
  /**
   * @description для пермишен, роутов
   */
  const getRole = computed(() => {
    const arrRoles: any[] = roles.value
    // const arrRoles = [EUserRoles.CUSTOMER_ADMIN] // test dev
    const type = (() => {
      if (arrRoles.includes(EUserRoles.ADMIN)) return 'Admin'
      if (arrRoles.includes(EUserRoles.CUSTOMER_ADMIN) || arrRoles.includes(EUserRoles.DOCUMENT_ADMIN)) return 'Edit'
      if (arrRoles.includes(EUserRoles.CUSTOMER_READ) || arrRoles.includes(EUserRoles.DOCUMENT_READ)) return 'User'
      return 'Admin' // default value
    })()
    const isAdmin = arrRoles.includes(EUserRoles.ADMIN)
    const isCustomerAdmin = arrRoles.includes(EUserRoles.CUSTOMER_ADMIN)
    const isCustomerRead = arrRoles.includes(EUserRoles.CUSTOMER_READ)
    const isDocumentAdmin = arrRoles.includes(EUserRoles.DOCUMENT_ADMIN)
    const isDocumentRead = arrRoles.includes(EUserRoles.DOCUMENT_READ)
    return {
      isAdmin,
      isCustomer: isCustomerAdmin || isCustomerRead,
      isCustomerAdmin,
      isCustomerRead,
      isDocument: isDocumentAdmin || isDocumentRead,
      isDocumentAdmin,
      isDocumentRead,
      type
    }
  })

  const login = async () => {
    const method = API.auth
    const response = await HTTP({ method, loader: true })
    const redirectUrl = response?.data?.redirect_url || ''
    if (redirectUrl) window.location.replace(redirectUrl)
    return response
  }
  /**
   * @description Костыль для авторизации гостевого просмотра
   * @param data
   */
  const loginPass = async (data: any) => {
    const method = API.authLogin
    const response = await http.post(method, data)
    if (response.status === 200) {
      const _token = response?.data?.data?.token || ''
      setCookie('token', _token)
      window.location.replace('/admin/paxum/clients')
    }
  }
  const logout = async () => {
    const _token = getCookie('token') || ''
    if (!_token) {
      window.location.replace('/')
      return
    }
    const method = API.logout
    const response = await getData({ method, loader: true })
    const redirectUrl = response?.data?.redirect_url || ''
    if (redirectUrl) {
      deleteCookie('token')
      deleteCookie('patch')
      window.location.replace(redirectUrl)
    }
    deleteCookie('token')
    deleteCookie('patch')
    return true
  }
  const setPlatform = (data: EPlatform) => {
    platform.value = data
  }
  const getToken = async (code: string) => {
    const method = API.login(code)
    const response = await HTTP({ method, loader: true })
    const _token = response?.data?.token || ''
    if (_token) {
      setCookie('token', _token)
      token.value = _token
      userInfo()
      return _token
    }
    login()
    return response
  }
  const userInfo = async () => {
    const projectSave = platform.value || ''
    const method = API.userInfo
    const response = await getData({ method, loader: true })
    const data = response?.data || ''
    if (data) {
      const _projects = data?.projects
      if (!Array.isArray(_projects) && !_projects?.[0]) return false
      platforms.value = _projects
      username.value = data.username
      roles.value = data.roles
      const _platform = _projects.includes(projectSave) ? projectSave : _projects[0]
      platform.value = _platform
      setCookie('platform', _platform)
      if (!_projects.includes(projectSave)) {
        window.location.replace(`/admin/${_platform}/clients`)
      }
      return _platform
    }
    return false
  }
  const managersList = async (term: string) => {
    const isAdmin = useStore().getRole.isAdmin
    if (!isAdmin) return {}
    if (term && managers.value.length) return managers.value
    const query = term ? '?query=' + term : ''
    const method = API.managers(query)
    const response = await getData({ method })
    managers.value = response?.data || {}
    return response
  }
  /**
   * @description Global Actions
   * @param data
   * @param data.method
   * @param data.params
   * @param data.loader - указывает включать или не включать лоадер при работе запроса
   */
  const getData = (data: any) => {
    if (!data.loader) isLoader.value = true
    const body = {
      ...data,
      method: data.method,
      params: data.params
    }
    return http
      .get(data.method, body)
      .then((res: AxiosResponse) => controllerResponse(data.method, res) || {})
      .catch((error: any) => {
        captureError(data.method, error, 'error')
        return false
      })
      .finally(() => {
        if (!data.loader) isLoader.value = false
      })
  }
  const setData = (data: any) => {
    const isNotify = (data.config && data.config.isNotify) || false
    isLoader.value = true
    return http
      .post(data.method, data.params)
      .then((res: AxiosResponse) => {
        if (!isNotify) {
          ElNotification({
            type: 'success',
            message: 'SAVE',
            duration: 2000
          })
        }
        return controllerResponse(data.method, res)
      })
      .catch((error: any) => captureError(data.method, error, 'error'))
      .finally(() => {
        isLoader.value = false
      })
  }
  const setFile = (data: any) => {
    const formData = new FormData()
    const headers = { 'content-type': 'multipart/form-data' }
    formData.append('form', data.params)
    isLoader.value = true
    return http
      .post(data.method, formData, { headers })
      .then((res: AxiosResponse) => {
        ElNotification({
          type: 'success',
          message: 'SAVE',
          duration: 2000
        })
        return controllerResponse(data.method, res)
      })
      .catch((error: any) => captureError(data.method, error, 'error'))
      .finally(() => {
        isLoader.value = false
      })
  }
  const setDelete = (data: any) => {
    isLoader.value = true
    return http
      .delete(data.method, data.params)
      .then((res: AxiosResponse) => {
        ElNotification({
          type: 'success',
          message: 'Delete',
          duration: 1000
        })
        return controllerResponse(data.method, res)
      })
      .catch((error: any) => {
        captureError(data.method, error, 'error')
        return false
      })
      .finally(() => {
        isLoader.value = false
      })
  }
  const patchData = (data: any) => {
    isLoader.value = true
    return http
      .patch(data.method, data.params)
      .then((res: AxiosResponse) => {
        ElNotification({
          type: 'success',
          message: 'SAVE',
          duration: 2000
        })
        return controllerResponse(data.method, res)
      })
      .catch((error: any) => {
        captureError(data.method, error, 'error')
        return false
      })
      .finally(() => {
        isLoader.value = false
      })
  }
  const putData = (data: any) => {
    isLoader.value = true
    return http
      .put(data.method, data.params)
      .then((res: AxiosResponse) => {
        ElNotification({
          type: 'success',
          message: 'SAVE',
          duration: 2000
        })
        return controllerResponse(data.method, res)
      })
      .catch((error: any) => {
        captureError(data.method, error, 'error')
        return false
      })
      .finally(() => {
        isLoader.value = false
      })
  }
  const HTTP = (data: any) => {
    if (!data.loader) isLoader.value = true
    const body = {
      method: data.method,
      params: data.params
    }
    return http
      .get(data.method, body)
      .then((res: AxiosResponse) => controllerResponse(data.method, res) || {})
      .catch((error: any) => {
        captureError(data.method, error, 'error')
        return false
      })
      .finally(() => {
        if (!data.loader) isLoader.value = false
      })
  }
  /**
   * @description  FETCH GET экспеерментальная сторонняя апи
   */
  const fetchApiGet = async ({ url, method = 'POST', body }: any) => {
    let countRequest = 0
    const _platform = platform.value || ''
    const getData: any = async () => {
      const config: any = {
        method,
        headers: {
          access_token: process.env.VUE_APP_API_PAXUM_TOKEN
        }
      }
      if (body) config.body = JSON.stringify(body)
      const link = `${Project.get(_platform)?.urlInfo}${url}`
      return await fetch(link, config)
        .then((request: Response) => {
          if (request.status === 500 && countRequest === 0) {
            countRequest += 1
            return getData()
          }
          return request.json()
        }).catch(() => {
          return []
        })
    }
    return await getData()
  }
  const queryGraph = async (val: any) => {
    const query = new URLSearchParams(val).toString()
    const method = API.queryGraph(query)
    const response = await getData({ method })
    const data = response?.data || []
    return graphTemplates(data, val.customerId)
  }
  const queryGraphTransfer = async (val: any) => {
    const query = new URLSearchParams(val).toString()
    const method = API.queryGraphTransfer(query)
    const response = await getData({ method })
    return response?.data || []
  }

  return {
    getRole,
    //
    logout,
    login,
    loginPass,
    getToken,
    userInfo,
    managersList,
    getData,
    setData,
    setFile,
    setDelete,
    patchData,
    putData,
    fetchApiGet,
    queryGraph,
    queryGraphTransfer,
    setPlatform,
    //
    lang,
    customerId,
    managers,
    username,
    platforms,
    roles,
    graph,
    platform,
    token,
    isLoader
  }
})
