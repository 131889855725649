<template>
  <button
    :disabled="disabled || loader"
    class="core-button"
    :class="[
      large && 'core-big',
      !color && 'core-btn-grey',
      // color === 'primary-full' && 'core-core-btn-blue-full',
      color === 'primary' && 'core-btn-blue',
      color === 'success' && 'core-btn-green',
      color === 'danger' && 'core-btn-red',
      color === 'white' && 'core-btn-white',
      color === 'danger-lite' && 'core-btn-red-text',
      color === 'warn' && 'core-btn-warn',
      disabled && 'core-btn-default',
      icon && 'core-btn-icon',
    ]"
  >
    <div v-if="icon && icon !== 'add'" :class="icon" class="icon"/>
    <div class="icon" v-if="icon === 'add'">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <circle cx="10" cy="10" r="10" transform="translate(2 2)" fill="#fff"/>
        <path
          d="M12.707,12l3.147-3.146a.5.5,0,0,0-.708-.708L12,11.293,8.854,8.146a.5.5,0,0,0-.708.708L11.293,12,8.146,15.146a.5.5,0,0,0,.708.708L12,12.707l3.146,3.147a.5.5,0,0,0,.708-.708Z"
          transform="translate(12 -4.971) rotate(45)"
          fill="currentColor"/>
      </svg>
    </div>
    <loader-icon v-if="loader" />
    <slot v-if="!loader"/>
  </button>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
const LoaderIcon = defineAsyncComponent(() => import('@/shared/loader/LoaderIcon.vue'))

defineProps<{
  icon?: 'add' | 'eye' | null
  color?: 'primary' | 'success' | 'danger' | 'white' | 'danger-lite' | 'warn' | null
  large?: boolean
  loader?: boolean
  disabled?: boolean
}>()

</script>

<style lang="scss" scoped>
.core-svg {
  height: 1rem;
  width: 1rem;
  padding-bottom: 2px;
  margin-right: 5px;
}

.core-big {
  width: 100% !important;
}

.core-button {
  border-radius: var(--border-radius);
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
  background-color: #ffffff00;
  height: 32px;
  line-height: 2.5;
  padding: 0 16px;
  text-decoration: none;
  box-sizing: border-box;
  user-select: none;
  outline: none;
  position: relative;
  font-stretch: normal;
  text-align: center;
  display: flex;
  align-items: center;
  width: auto;
  cursor: pointer;
  justify-content: center;
  transition: .2s;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  opacity: 1;

  &.disabled {
    border: solid 1px #d1d9e3;
    color: var(--title-color);
    cursor: not-allowed;
    background-color: #fff;

    &:active {
      transform: translate(0px, 0px);
    }
  }
}

.core-button:hover {
  border: 1px solid #0000001F;
}

.core-btn-icon {
  padding-left: 7px;
}
.icon {
  margin-right: 7px;
  height: 22px;
  min-height: 22px;
  width: 22px;
  min-width: 22px;
  display: flex;
  align-items: center;
}

// icon
.eye {
  background: url(@/assets/icon/eye-white.svg) no-repeat center;
}

.blue {
  background-color: #55acee;
}

.blue:hover {
  background-color: #6FC6FF;
}

.white {
  background-color: var(--background-color);
  border: solid 1px #d1d9e3;
  height: 30px;
}

.btn-middle {
  width: 200px;
}

.core-btn-green {
  border: 1px solid var(--success-color);
  background-color: var(--success-color);
  color: #FFFFFF;
  & .icon {
    color: var(--success-color);
  }
  &:active {
    background: #509E1C 0 0 no-repeat padding-box;
  }
}

.core-btn-red {
  border: 1px solid var(--danger-color);
  background-color: var(--danger-color);
  color: #FFFFFF;

  &:active {
    background: #DC3545 0 0 no-repeat padding-box;
  }
}

.core-btn-red-text {
  color: var(--danger-color);
  background: #F7F7F7 0 0 no-repeat padding-box;
  &:hover {
    border: 1px solid var(--danger-color);
  }
  &:active {
    background: #DC3545 0 0 no-repeat padding-box;
    color: #FFFFFF;
  }
}

.core-btn-warn {
  border: 1px solid var(--warning-color);
  background-color: var(--warning-color);
  color: #FFFFFF;
}

.core-btn-blue {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #FFFFFF;
  & .icon {
    color: var(--primary-color);
  }
  &:active {
    background: #327DE6 0 0 no-repeat padding-box;
  }
}

.core-btn-white {
  border: 1px solid #F7F7F7;
  background-color: #ffffff;
  opacity: 1 !important;
  color:  var(--primary-color);
  & .icon {
    color: var(--primary-color);
  }
  &:active {
    background: #F7F7F7 0 0 no-repeat padding-box;
  }
}

.core-btn-default {
  opacity: 0.7;
}

.core-btn-grey {
  //border: solid 1px #d1d9e3;
  border: solid 1px #FFFFFF;
  background-color: #F7F7F7;
  color: var(--primary-color);

  &:active {
    background: var(--primary-color) 0 0 no-repeat padding-box;
    color: #FFFFFF;
  }
}

@media (max-width: 400px) {
  .btn {
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  .core-button {
    width: 100%;
    padding: 0;
  }
}

</style>
