import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/icon/btn_arrow_down.svg'
import _imports_1 from '@/assets/icon/user.svg'


const _hoisted_1 = { class: "admin-bar" }
const _hoisted_2 = { class: "bar" }
const _hoisted_3 = {
  key: 0,
  class: "bar-item_menu"
}
const _hoisted_4 = { class: "el-dropdown-link span4 wrap el-row--flex ac caps mx" }
const _hoisted_5 = { class: "caps" }
const _hoisted_6 = { class: "f-12 caps" }
const _hoisted_7 = { class: "bar-item_menu" }
const _hoisted_8 = { class: "bar-username" }
const _hoisted_9 = { class: "span11" }
const _hoisted_10 = { class: "menu-item" }
const _hoisted_11 = { class: "menu-item_label" }
const _hoisted_12 = {
  key: 0,
  class: "menu-item_body"
}

import { ref, computed } from 'vue'
import { deleteCookie } from '@/helpers/cookie'

import dayjs from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/pinia/global'
import { EPlatform } from '@/api/project'
import { useCustomerStore } from '@/components/Admin/Clients/pinia'
// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'AdminBar',
  setup(__props) {

const buildDateVal = dayjs(BUILD_DATE).format('HH:mm - DD/MM') || ''
const route:any = useRoute()
const router:any = useRouter()
// name: 'AdminBar',

const buildDate = ref(buildDateVal)

const username = computed(() => useStore().username)
const roles = computed(() => useStore().getRole)
const platform = computed(() => useStore().platform)
const platforms = computed(() => useStore().platforms)
const menus = computed(() => {
  return [
    {
      id: 10, name: 'Clear Cache', method: 'clearSw', icon: 'repeat', body: buildDate.value
    },
    {
      id: 9, name: 'Logout', method: 'logout', icon: 'logout'
    }
  ]
})

const clients = () => {
  return useCustomerStore().clientsList()
}
const setStatePlatform = (event:EPlatform) => {
  return useStore().setPlatform(event)
}
const home = () => {
  window.location.replace('/')
}

const setPlatform = (name:EPlatform) => {
  setStatePlatform(name)
  router.push({
    name: 'clients',
    params: { platform: name }
  })
  clients()
}

const clearSw = () => {
  if (navigator?.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister()
      }
    })
  }
  deleteCookie('token')
  deleteCookie('patch')
  deleteCookie('platform')
  // localStorage.removeItem('vuex')
  router.push({ name: 'home' })
  window.location.reload()
}

const logout = () => {
  router.push({ name: 'logout' })
}

const menuLink = (name: string) => {
  switch (name) {
    case 'clearSw':
      clearSw()
      break
    case 'logout':
      logout()
      break
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "admin-bar_logo",
      onClick: home
    }, " KYC "),
    _createElementVNode("div", _hoisted_2, [
      (roles.value.isCustomer || roles.value.isAdmin)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: {name:'clients'}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_unref(route).meta.id === '0' ? 'bar-item active': 'bar-item')
              }, "Clients", 2)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (roles.value.isAdmin)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 1,
            to: {name:'settings'}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$route.meta.id === '3' ? 'bar-item active': 'bar-item')
              }, "Settings", 2)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "el-row--flex ae" }, null, -1)),
    false
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_el_dropdown, { trigger: "click" }, {
            dropdown: _withCtx(() => [
              _createVNode(_component_el_dropdown_menu, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(platforms.value, (item) => {
                    return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                      key: item,
                      value: item,
                      onClick: ($event: any) => (setPlatform(item))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(item), 1)
                      ]),
                      _: 2
                    }, 1032, ["value", "onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(platform.value), 1),
                _cache[0] || (_cache[0] = _createElementVNode("img", {
                  src: _imports_0,
                  height: "24",
                  alt: "",
                  class: "ml_5"
                }, null, -1))
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_el_dropdown, {
        trigger: "click",
        onCommand: menuLink
      }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_el_dropdown_menu, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menus.value, (menu) => {
                return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                  command: menu.method,
                  key: menu.i
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", {
                          class: _normalizeClass(menu.icon)
                        }, null, 2),
                        _createTextVNode(" " + _toDisplayString(menu.name), 1)
                      ]),
                      (menu.body)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, "Build: " + _toDisplayString(menu.body), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1032, ["command"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              src: _imports_1,
              alt: "",
              height: "40",
              width: "40",
              class: "mr-1"
            }, null, -1)),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(username.value) + " (" + _toDisplayString(roles.value.type) + ")", 1)
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("img", {
              src: _imports_0,
              height: "24",
              alt: "",
              class: "ml_5"
            }, null, -1))
          ])
        ]),
        _: 1
      })
    ])
  ]))
}
}

})